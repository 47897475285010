export function dataCalculate(type) {
  let date = new Date();
  let kssj = '';
  let jssj = '';
  const year = date.getFullYear();
  const nowMonth = date.getMonth();

  if (type === 0) {// 年
    kssj = year + '-01-01';
    jssj = year + '-12-31'

  } else if (type === 1) {
    let quarterStartMonth = 0;
    if (nowMonth < 3) {
      quarterStartMonth = 0;
      jssj = year + '-' + (quarterStartMonth + 3) + '-31'
    }
    if (2 < nowMonth && nowMonth < 6) {
      quarterStartMonth = 3;
      jssj = year + '-' + (quarterStartMonth + 3) + '-30'
    }
    if (5 < nowMonth && nowMonth < 9) {
      quarterStartMonth = 6;
      jssj = year + '-' + (quarterStartMonth + 3) + '-31'
    }
    if (nowMonth > 8) {
      quarterStartMonth = 9;
      jssj = year + '-' + (quarterStartMonth + 3) + '-31'
    }
    kssj = year + '-' + (quarterStartMonth + 1) + '-01';
  } else if (type === 2) {
    kssj = new Date(year, nowMonth, 1)
    jssj = new Date(year, nowMonth, getMonthDaysFun(nowMonth))
  } else if (type === 3) {
    kssj = year + '-' + nowMonth + '-' + date.getDate()
    jssj = kssj;
  }
  return {kssj: new Date(kssj), jssj: new Date(jssj)}
}

export function getMonthDaysFun(myMonth) {
  var date = new Date();
  var nowYear = date.getFullYear(); //当前年
  var monthStartDate = new Date(nowYear, myMonth, 1);
  var monthEndDate = new Date(nowYear, myMonth + 1, 1);
  var days = (monthEndDate - monthStartDate) / (1000 * 60 * 60 * 24);
  return days;
}

export function getTagColor(shzt) {
  if (shzt == '1') {
    return "primary";
  } else if (shzt != '0' && shzt.length === 1) {
    return "success";
  } else {
    return "danger"
  }
}


/** 根据传入的字段进行排序
 * @param arr 需要排序的数组
 * @param property 排序的字段
 * @param desc true.升序 false.降序
 * @returns {*} 排好序后的数组
 */
export function abilitySort(arr, property, desc) {
  return arr.sort(function (a, b) {
    const val1 = a[property];
    const val2 = b[property];
    if (desc) {
      return val1 - val2; // 升序排列
    } else {
      return val2 - val1; // 降序排列
    }
  });
}
